import * as React from "react"
import {
    Box, Center,
    ChakraProvider, HStack, Link, VStack,
} from "@chakra-ui/react"
import {FaArrowCircleUp} from "react-icons/all";

export const ScrollToTop = () => {
    const [scrollPosition, setScrollPosition] = React.useState(0);
    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };

    React.useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    return (
        <>
            {scrollPosition > 500 && (<Link href='/#top'>
                <Box position='fixed'
                     bottom='20px'
                     right={['16px', '36px']}
                     zIndex={1}
                     // color={['white', 'black']}
                >
                    <Center>
                        Go to top  &nbsp;
                        <FaArrowCircleUp/>
                    </Center>

                    {/*<Image src='images/icons/top.svg'*/}
                    {/*       w='60px'*/}
                    {/*       h='60px'*/}
                    {/*/>*/}
                </Box>
            </Link>)}
        </>

    )
}