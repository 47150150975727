import React from "react";
import {
    chakra,
    Box,
    useColorModeValue,
    Button,
    Stack,
    Image,
    SimpleGrid,
    Text,
    Icon, GridItem, VisuallyHidden, Input,
} from "@chakra-ui/react";
import {PopupButton} from "@typeform/embed-react";

// function to time out 2000 ms
const timeout = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export const Intro = () => {
    const [joinedEarlyAccess, setJoinedEarlyAccess] = React.useState(false);

    return (
        <Box minH="100vh" px={8} paddingTop={64} mx="auto">
            <Box
                w={{ base: "full", md: 11 / 12, xl: 9 / 12 }}
                mx="auto"
                textAlign={{ base: "left", md: "center" }}
            >
                <chakra.h1
                    mb={6}
                    fontSize={{ base: "8xl", md: "10xl" }}
                    fontWeight="bold"
                    lineHeight="none"
                    letterSpacing={{ base: "normal", md: "tight" }}
                    color={useColorModeValue("gray.900",'gray.100')}
                >
                    All your{" "}
                    <Text
                        display={{ base: "block", lg: "inline" }}
                        w="full"
                        bgClip="text"
                        bgGradient="linear(to-l, red.400,purple.500)"
                        fontWeight="extrabold"
                    >
                        NFTs
                    </Text>{" "}
                    in one single place.
                </chakra.h1>
                <chakra.p
                    px={{ base: 0, lg: 24 }}
                    mb={6}
                    fontSize={{ base: "lg", md: "xl" }}
                    color={useColorModeValue("gray.600",'gray.300')}
                >
                    Nifty Hub is a NFT management dapp that makes having NFT a joy with its smart contract based tools.
                    One and only dapp you need.
                    {/*with its smart contract*/}
                    {/*based tools which allows you to create folders for organizing your NFTs, burning spam NFTs with couple clicks,*/}
                    {/*changing your social media profile picture and cover to NFTs with just a few clicks.*/}
                    {/*Discovery tools are integral part of Nifty Hub where you can create signals*/}
                    {/*for NFT projects and check graphics to better understand the NFT market.*/}
                </chakra.p>
                <Stack
                    direction={{base:"column",sm:"row"}}
                    mb={{ base: 4, md: 8 }}
                    spacing={2}
                    justifyContent={{ sm: "center", md: "center" }}
                >
                    <Button
                        as={PopupButton}
                        w="half"
                        variant="solid"
                        id="TIIpyGkK"
                        size="lg"
                        autoClose={2000}
                        cursor="pointer"
                        bgGradient="linear(to-l, red.400,purple.500)"
                        isDisabled={joinedEarlyAccess}
                        keepSession={true}
                        onSubmit={() => {
                            timeout(2000).then(() => {
                                setJoinedEarlyAccess(true);
                            });
                            // setJoinedEarlyAccess(true)
                        }}
                        _hover={
                            {
                                bgGradient: "linear(to-l, red.500,purple.600)",
                                color: "white",
                            }
                        }
                    >

                        <Text
                            fontSize={{ base: "lg", md: "xl" }}
                            fontWeight="extrabold"
                            color={useColorModeValue("gray.50",'gray.100')}>
                            { joinedEarlyAccess ? "Thank you!" : "Get Early Access"}

                        </Text>

                    </Button>
                    {/*<PopupButton id="TIIpyGkK" style={{ fontSize: 20 }} className="my-button">*/}
                    {/*    <Text*/}
                    {/*        fontSize={{ base: "lg", md: "xl" }}*/}
                    {/*        fontWeight="extrabold"*/}
                    {/*        color={useColorModeValue("gray.50",'gray.100')}>*/}
                    {/*        Get Early Access*/}

                    {/*    </Text>*/}
                    {/*</PopupButton>*/}
                    {/*<SimpleGrid*/}
                    {/*                        as="form"*/}
                    {/*                        w={{ base: "full", md: 7 / 12 }}*/}
                    {/*                        columns={{ base: 1, lg: 6 }}*/}
                    {/*                        spacing={3}*/}
                    {/*                        pt={1}*/}
                    {/*                        mx="auto"*/}
                    {/*                        mb={8}*/}
                    {/*                    >*/}
                    {/*<GridItem as="label" colSpan={{ base: "auto", lg: 4 }}>*/}
                    {/*    <VisuallyHidden>Your Email</VisuallyHidden>*/}
                    {/*    <VisuallyHidden>*/}
                    {/*        <Input*/}
                    {/*            borderColor={useColorModeValue("gray.800",'whiteAlpha.900')}*/}
                    {/*            mt={0}*/}
                    {/*            size="lg"*/}
                    {/*            type="email"*/}
                    {/*            placeholder="Enter your email..."*/}
                    {/*            required={false}*/}
                    {/*        />*/}
                    {/*    </VisuallyHidden>*/}

                    {/*</GridItem>*/}
                    {/*<Button*/}
                    {/*    as={GridItem}*/}
                    {/*    w="full"*/}
                    {/*    variant="solid"*/}
                    {/*    colSpan={{ base: "auto", lg: 2 }}*/}
                    {/*    size="lg"*/}
                    {/*    type="submit"*/}
                    {/*    // colorScheme="brand"*/}
                    {/*    cursor="pointer"*/}
                    {/*    bgGradient="linear(to-l, red.400,purple.500)"*/}
                    {/*>*/}
                    {/*    <PopupButton id="TIIpyGkK" style={{ fontSize: 20 }} className="my-button">*/}
                    {/*        <Text*/}
                    {/*            fontSize={{ base: "lg", md: "xl" }}*/}
                    {/*            fontWeight="extrabold"*/}
                    {/*            color={useColorModeValue("gray.50",'gray.100')}>*/}
                    {/*            Get Early Access*/}

                    {/*        </Text>*/}
                    {/*    </PopupButton>*/}
                    {/*</Button>*/}
                    {/*</SimpleGrid>*/}
                    {/*<Button*/}
                    {/*    as="a"*/}
                    {/*    variant="solid"*/}
                    {/*    display="inline-flex"*/}
                    {/*    alignItems="center"*/}
                    {/*    justifyContent="center"*/}
                    {/*    w={{ base: "full", sm: "auto" }}*/}
                    {/*    mb={{ base: 2, sm: 0 }}*/}
                    {/*    size="lg"*/}
                    {/*    cursor="pointer"*/}
                    {/*>*/}
                    {/*    Get Started*/}
                    {/*    <Icon boxSize={4} ml={1} viewBox="0 0 20 20" fill="currentColor">*/}
                    {/*        <path*/}
                    {/*            fillRule="evenodd"*/}
                    {/*            d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"*/}
                    {/*            clipRule="evenodd"*/}
                    {/*        />*/}
                    {/*    </Icon>*/}
                    {/*</Button>*/}
                </Stack>
            </Box>
            <Box
                w={{ base: "full", md: 10 / 12 }}
                mx="auto"
                mt={20}
                textAlign="center"
            >
                {/*IMAGE CAN BE ADDED HERE*/}
                {/*<Image*/}
                {/*    w="full"*/}
                {/*    rounded="lg"*/}
                {/*    shadow="2xl"*/}
                {/*    src="https://kutty.netlify.app/hero.jpg"*/}
                {/*    alt="Hellonext feedback boards software screenshot"*/}
                {/*/>*/}
            </Box>
        </Box>
    );
};



// import React from "react";
// import {
//     chakra,
//     Box,
//     useColorModeValue,
//     Flex,
//     SimpleGrid,
//     GridItem,
//     VisuallyHidden,
//     Input,
//     Button,
//     Stack,
//     Icon,
// } from "@chakra-ui/react";
//
// const KuttyHero = () => {
//     const Feature = (props) => (
//         <Flex alignItems="center" color={useColorModeValue(null, "white")}>
//             <Icon
//                 boxSize={4}
//                 mr={1}
//                 color="green.600"
//                 viewBox="0 0 20 20"
//                 fill="currentColor"
//             >
//                 <path
//                     fillRule="evenodd"
//                     d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
//                     clipRule="evenodd"
//                 ></path>
//             </Icon>
//             {props.children}
//         </Flex>
//     );
//     return (
//         <Box px={4} py={32} mx="auto">
//             <Box
//                 w={{ base: "full", md: 11 / 12, xl: 8 / 12 }}
//                 textAlign={{ base: "left", md: "center" }}
//                 mx="auto"
//             >
//                 <chakra.h1
//                     mb={3}
//                     fontSize={{ base: "4xl", md: "5xl" }}
//                     fontWeight={{ base: "bold", md: "extrabold" }}
//                     color={useColorModeValue("gray.900", "gray.100")}
//                     lineHeight="shorter"
//                 >
//                     A secure, faster way to transfer.
//                 </chakra.h1>
//                 <chakra.p
//                     mb={6}
//                     fontSize={{ base: "lg", md: "xl" }}
//                     color="gray.500"
//                     lineHeight="base"
//                 >
//                     We’re on a mission to bring transparency to finance. We charge as
//                     little as possible, and we always show you upfront. No hidden fees. No
//                     bad exchange rates. No surprises.
//                 </chakra.p>
//                 <SimpleGrid
//                     as="form"
//                     w={{ base: "full", md: 7 / 12 }}
//                     columns={{ base: 1, lg: 6 }}
//                     spacing={3}
//                     pt={1}
//                     mx="auto"
//                     mb={8}
//                 >
//                     <GridItem as="label" colSpan={{ base: "auto", lg: 4 }}>
//                         <VisuallyHidden>Your Email</VisuallyHidden>
//                         <Input
//                             mt={0}
//                             size="lg"
//                             type="email"
//                             placeholder="Enter your email..."
//                             required="true"
//                         />
//                     </GridItem>
//                     <Button
//                         as={GridItem}
//                         w="full"
//                         variant="solid"
//                         colSpan={{ base: "auto", lg: 2 }}
//                         size="lg"
//                         type="submit"
//                         colorScheme="brand"
//                         cursor="pointer"
//                     >
//                         Get Started
//                     </Button>
//                 </SimpleGrid>
//                 <Stack
//                     display="flex"
//                     direction={{ base: "column", md: "row" }}
//                     justifyContent={{ base: "start", md: "center" }}
//                     mb={3}
//                     spacing={{ base: 2, md: 8 }}
//                     fontSize="xs"
//                     color="gray.600"
//                 >
//                     <Feature>No credit card required</Feature>
//                     <Feature>14 days free</Feature>
//                     <Feature>Cancel anytime</Feature>
//                 </Stack>
//             </Box>
//         </Box>
//     );
// };
//
// export default KuttyHero;