import * as React from "react"
import {
    Box, Center,
    ChakraProvider, HStack, Link, VStack,
} from "@chakra-ui/react"
import {Header} from "./sections/Header";
import {Landing} from "./sections/Landing";
import {Footer} from "./sections/Footer";
// import {Header} from "./sections/Header";
import theme from "./theme";
import {FaArrowCircleUp, FaArrowUp} from "react-icons/all";
import {ScrollToTop} from "./helper/ScrollToTop";

export const App = () => {
    return (
        <ChakraProvider theme={theme}>
            <Header/>
            {/*<Header/>*/}
            <Landing/>
            <Footer/>
            <ScrollToTop/>
        </ChakraProvider>
    )
}