// Sample card from Airbnb

// This is how the "timelineData" imported above should look like
import {Box, Divider, Flex, Heading, SimpleGrid, useColorModeValue, useMediaQuery} from "@chakra-ui/react";
import {TimelineRow} from "../components/TimelineRow";
import {
    BiRun,
    CgPlayListSearch,
    GiWaterDrop,
    GoMilestone, GoOrganization,
    HiOutlineArrowNarrowDown,
    HiOutlineArrowNarrowRight, IoMdSchool,
    RiFileList3Line,
    RiGalleryFill,
    RiRunFill,
    RiTestTubeFill,
    VscMilestone
} from "react-icons/all";
import React from "react";

const roadmapItems = [
    {
        title: "1st Quarter",
        items: [
            {
                index: "first",
                logo: RiFileList3Line,
                title: "Waitlist",
                date: "Registration opens",
                color: "teal.300",
            },
            {
                index: "second",
                logo: RiTestTubeFill,
                title: "Beta testing",
                date: "Invitation to waitlist participants",
                color: "blue.300",
            },
            {
                index: "third",
                logo: BiRun,
                title: "Hackathon",
                date: "Presentation to judge and VCs",
                color: "red.300",
            },
            {
                index: "fourth",
                logo: GoMilestone,
                title: "Release",
                date: "Core MVP features are ready",
                color: "orange.300",
            },
        ]
    },
    {
        title: "2nd Quarter",
        items: [
            {
                index: "fifth",
                logo: RiGalleryFill,
                title: "Showroom Release",
                date: "NFT creative visualization",
                color: "pink.300",
            },
            {
                index: "sixth",
                logo: CgPlayListSearch,
                title: "Discovery Tools",
                date: "New capabilities are ready",
                color: "red.300",
            },
            {
                index: "seventh",
                logo: GiWaterDrop,
                title: "NFT Drop",
                date: "Premium NFT to active users",
                color: "cyan.300",
            },
            // {
            //     index: "seventh",
            //     logo: VscMilestone,
            //     title: "New card added for order #3210145",
            //     date: "20 DEC 3:52 PM",
            //     color: "yellow.300",
            // },
        ]
    },
    {
        title: "3rd Quarter",
        items: [
            {
                index: "eight",
                logo: CgPlayListSearch,
                title: "Expert support",
                date: "Easy q&a service",
                color: "red.300",
            },
            {
                index: "ninth",
                logo: RiRunFill,
                title: "Beta mobile app",
                date: "",
                color: "teal.300",
            },
            {
                index: "tenth",
                logo: VscMilestone,
                title: "Discovery v2",
                date: "Support for creators",
                color: "yellow.300",
            },
        ]
    },
    {
        title: "4th Quarter",
        items: [
            {
                index: "eleventh",
                logo: CgPlayListSearch,
                title: "Sale tools ",
                date: "Optimize your sales",
                color: "red.300",
            },
            {
                index: "twelfth",
                logo: IoMdSchool,
                title: "Academy and Blog",
                date: "Launch of Academy and Blog",
                color: "teal.300",
            },
            {
                index: "seventh",
                logo: GoOrganization,
                title: "Token airdrop",
                date: "Token airdrop and getting ready for DAO",
                color: "yellow.300",
            },
        ]
    }
]


export function Roadmap() {
    const textColor = useColorModeValue("gray.700", "white.300")
    const bgIconColor = useColorModeValue("white.300", "gray.700")
    const bg = useColorModeValue("gray.50", "gray.700")
    const [isLargerThan990] = useMediaQuery('(min-width: 990px)')

    const arrowIcon = isLargerThan990 ? <HiOutlineArrowNarrowRight/> : <HiOutlineArrowNarrowDown/>

    return (
        <Flex
            id="Roadmap"
            minH="100vh"
            // bgGradient={useColorModeValue("radial(orange.100, purple.300)", 'radial(red.100, purple.900)'
            // )}
            bgGradient="linear(to-t, transparent, purple.200 40%, red.100 60%, transparent 105%)"
            py={20}
            px={{base: 2, md: 20}}
            w="auto"
            justifyContent="center"
            alignItems="center"
        >
            <Box
                maxW="container.xl"
                shadow="xl"
                bg={useColorModeValue("white", "gray.800")}

                // bg={useColorModeValue("gray.800", "whiteAlpha.50")}
                px={{base: 2, md: 8}}
                py={20}
                mx="auto"
            >
                <Heading size="2xl">
                    Roadmap 2022
                </Heading>
                <Divider/>
                <Heading size="2xl">
                    &nbsp;
                </Heading>

                <SimpleGrid columns={{base: 2, lg: 1}}>

                    {
                        roadmapItems.map((quarter, index, arr) => {
                            return (
                                <Box>
                                    <Heading key={quarter.title} size="lg">
                                        {quarter.title}
                                    </Heading>
                                    <Flex direction={{base: "column", lg: "row"}}>
                                        {quarter.items.map((row, index, arr) => {
                                            return (
                                                <Flex direction={{base: "column", lg: "row"}} alignItems="center"
                                                      key={row.index}>
                                                    <TimelineRow
                                                        logo={row.logo}
                                                        title={row.title}
                                                        date={row.date}
                                                        color={row.color}
                                                        index={index}
                                                        arrLength={arr.length}
                                                    />
                                                    {
                                                        index !== arr.length - 1 &&
                                                        arrowIcon
                                                    }
                                                    {" "}
                                                </Flex>
                                            )
                                        })}
                                    </Flex>
                                </Box>
                            )

                        })}

                    {/*    <Heading size="lg">*/}
                    {/*    1st Quarter*/}
                    {/*</Heading>*/}
                    {/*<Flex direction={{base: "column", lg: "row"}}>*/}
                    {/*    {firstQuarter.map((row, index, arr) => {*/}
                    {/*        return (*/}
                    {/*            <Flex direction={{base: "column", lg: "row"}} key={row.index} alignItems="center">*/}

                    {/*                <TimelineRow*/}
                    {/*                    logo={row.logo}*/}
                    {/*                    title={row.title}*/}
                    {/*                    date={row.date}*/}
                    {/*                    color={row.color}*/}
                    {/*                    index={index}*/}
                    {/*                    arrLength={arr.length}*/}
                    {/*                />*/}
                    {/*                {*/}
                    {/*                    index !== arr.length - 1 &&*/}
                    {/*                    arrowIcon*/}
                    {/*                }*/}
                    {/*                {" "}*/}
                    {/*            </Flex>*/}
                    {/*        )*/}
                    {/*    })}*/}
                    {/*</Flex>*/}
                    {/*<Heading size="lg">*/}
                    {/*    2nd Quarter*/}
                    {/*</Heading>*/}
                    {/*<Flex direction={{base: "column", lg: "row"}}>*/}
                    {/*    {secondQuarter.map((row, index, arr) => {*/}
                    {/*        return (*/}
                    {/*            <Center key={row.index}>*/}
                    {/*                <TimelineRow*/}
                    {/*                    logo={row.logo}*/}
                    {/*                    title={row.title}*/}
                    {/*                    date={row.date}*/}
                    {/*                    color={row.color}*/}
                    {/*                    index={index}*/}
                    {/*                    arrLength={arr.length}*/}
                    {/*                />*/}
                    {/*                {*/}
                    {/*                    index !== arr.length - 1 &&*/}
                    {/*                    <HiOutlineArrowNarrowRight/>*/}
                    {/*                }*/}
                    {/*                {" "}*/}
                    {/*            </Center>*/}
                    {/*        )*/}
                    {/*    })}*/}
                    {/*</Flex>*/}
                </SimpleGrid>
            </Box>
        </Flex>
    )
}