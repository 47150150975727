import React from "react";

import {
    chakra,
    Box,
    Flex,
    useColorModeValue,
    Icon,
    SimpleGrid,
    Stack,
    GridItem,
    Container, useTheme,
} from "@chakra-ui/react";
import {SearchIcon} from "@chakra-ui/icons";
import {FaSearch, FaSearchDollar} from "react-icons/all";


export function Features() {

    const themeBg = useColorModeValue("white", "gray.800");

    const Feature = (props: any) => {
        return (
            <Flex>
                <Flex shrink={0}>
                    <Icon
                        boxSize={5}
                        mt={1}
                        mr={2}
                        color={useColorModeValue("gray.900", "whiteAlpha.800")}
                        viewBox="0 0 20 20"
                        fill="currentColor"
                    >
                        {props.icon}
                        {/*<path*/}
                        {/*    fillRule="evenodd"*/}
                        {/*    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"*/}
                        {/*    clipRule="evenodd"*/}
                        {/*></path>*/}
                    </Icon>
                </Flex>
                <Box ml={4}>
                    <chakra.dt
                        fontSize="lg"
                        fontWeight="bold"
                        lineHeight="6"
                        color={useColorModeValue("gray.900", "whiteAlpha.800")}
                    >
                        {props.title}
                    </chakra.dt>
                    <chakra.dd mt={2} color={useColorModeValue("gray.500", "gray.400")}>
                        {props.children}
                    </chakra.dd>
                </Box>
            </Flex>
        );
    };
    return (
        <Flex
            id="Features"
            minH="100vh"
            bgGradient="linear(to-b, transparent, purple.200 40%, red.100 60%, transparent 105%)"
            py={20}
            px={{base: 2, md: 20}}
            w="auto"
            justifyContent="center"
            alignItems="center"
        >
            <Box
                maxW="container.xl"
                // minW={{base: "container.xl", sm: "100vw"}}
                shadow="xl"
                bg={useColorModeValue("white", "gray.800")}
                px={8}
                py={20}
                mx="auto"
            >
                <SimpleGrid
                    alignItems="center"
                    columns={{base: 1, lg: 3}}
                    spacingY={{base: 10, lg: 32}}
                    spacingX={{base: 10, lg: 24}}
                >
                    <Box alignSelf="start">
                        <chakra.h2
                            color={useColorModeValue("brand.500", "brand.300")}
                            fontWeight="semibold"
                            textTransform="uppercase"
                            letterSpacing="wide"
                        >
                            What nft enthusiast needs
                        </chakra.h2>
                        <chakra.h2
                            mb={3}
                            fontSize={{base: "3xl", md: "4xl"}}
                            fontWeight="extrabold"
                            textAlign={{base: "center", sm: "left"}}
                            color={useColorModeValue("black", "white")}
                            lineHeight="shorter"
                            letterSpacing="tight"
                        >
                            All-in-one platform
                        </chakra.h2>
                        <chakra.p
                            mb={6}
                            fontSize={{base: "lg", md: "xl"}}
                            textAlign={{base: "center", sm: "left"}}
                            color={useColorModeValue("gray.600", "gray.500")}
                        >
                            Easily manage your NFT assets, and use them in full capacity with a few clicks.
                        </chakra.p>
                    </Box>
                    <GridItem colSpan={2}>
                        <Stack
                            spacing={{base: 10, md: 0}}
                            display={{md: "grid"}}
                            gridTemplateColumns={{md: "repeat(2,1fr)"}}
                            gridColumnGap={{md: 8}}
                            gridRowGap={{md: 10}}
                        >
                            <Feature
                                title="Organize NFTs"
                                icon={<path
                                    fillRule="evenodd"
                                    d="M6.672 1.911a1 1 0 10-1.932.518l.259.966a1 1 0 001.932-.518l-.26-.966zM2.429 4.74a1 1 0 10-.517 1.932l.966.259a1 1 0 00.517-1.932l-.966-.26zm8.814-.569a1 1 0 00-1.415-1.414l-.707.707a1 1 0 101.415 1.415l.707-.708zm-7.071 7.072l.707-.707A1 1 0 003.465 9.12l-.708.707a1 1 0 001.415 1.415zm3.2-5.171a1 1 0 00-1.3 1.3l4 10a1 1 0 001.823.075l1.38-2.759 3.018 3.02a1 1 0 001.414-1.415l-3.019-3.02 2.76-1.379a1 1 0 00-.076-1.822l-10-4z"
                                    clipRule="evenodd"
                                />}
                            >
                                Create folders to keep the similar NFTS together, and delete spam NFTs.
                                Automatic spam classification makes collection overview beauty to look at.{" "}
                            </Feature>
                            <Feature
                                title="Realistic price estimation"
                                icon={
                                    <FaSearchDollar size="1rem"/>
                                }
                            >
                                Get the price estimation tailored for your collection with the help of machine learning
                                based on historical data and rarity of your assets.
                            </Feature>
                            <Feature
                                title="Discover next big project"
                                icon={
                                    <FaSearch size="1rem"/>
                                }
                            >
                                {" "}
                                Find new projects with our discovery tools, and get detail overview of all the aspect
                                of the project.{" "}
                            </Feature>
                            <Feature
                                title="Manage your social presence"
                                icon={<path
                                    fillRule="evenodd"
                                    d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
                                    clipRule="evenodd"
                                />}
                            >
                                {" "}
                                Change your pfp, cover photo and bio on social media platforms easily. Replace your old
                                profile images with your NFT art.

                            </Feature>

                            <Feature
                                title="Show your collection in creative way"
                                icon={<path
                                    fillRule="evenodd"
                                    d="M5 2a1 1 0 011 1v1h1a1 1 0 010 2H6v1a1 1 0 01-2 0V6H3a1 1 0 010-2h1V3a1 1 0 011-1zm0 10a1 1 0 011 1v1h1a1 1 0 110 2H6v1a1 1 0 11-2 0v-1H3a1 1 0 110-2h1v-1a1 1 0 011-1zM12 2a1 1 0 01.967.744L14.146 7.2 17.5 9.134a1 1 0 010 1.732l-3.354 1.935-1.18 4.455a1 1 0 01-1.933 0L9.854 12.8 6.5 10.866a1 1 0 010-1.732l3.354-1.935 1.18-4.455A1 1 0 0112 2z"
                                    clipRule="evenodd"
                                />}
                            >
                                {" "}
                                Show your selected collection pieces in your profile.
                            </Feature>
                            <Feature
                                title="Connect with experts"
                                icon={
                                    <path
                                        fillRule="evenodd"
                                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-2 0c0 .993-.241 1.929-.668 2.754l-1.524-1.525a3.997 3.997 0 00.078-2.183l1.562-1.562C15.802 8.249 16 9.1 16 10zm-5.165 3.913l1.58 1.58A5.98 5.98 0 0110 16a5.976 5.976 0 01-2.516-.552l1.562-1.562a4.006 4.006 0 001.789.027zm-4.677-2.796a4.002 4.002 0 01-.041-2.08l-.08.08-1.53-1.533A5.98 5.98 0 004 10c0 .954.223 1.856.619 2.657l1.54-1.54zm1.088-6.45A5.974 5.974 0 0110 4c.954 0 1.856.223 2.657.619l-1.54 1.54a4.002 4.002 0 00-2.346.033L7.246 4.668zM12 10a2 2 0 11-4 0 2 2 0 014 0z"
                                        clipRule="evenodd"
                                    />
                                }
                            >
                                {" "}
                                Get in touch with experts when you need help with taxation and copyright violation {" "}
                            </Feature>
                        </Stack>
                    </GridItem>
                </SimpleGrid>
            </Box>
        </Flex>
    );
}