import * as React from "react"
import {
    Box,
} from "@chakra-ui/react"
import {Intro} from "../pages/Intro";
import {Features} from "../pages/Features";
import {Roadmap} from "../pages/Roadmap";

export const Landing = () => (
    <Box>
        <Intro/>
        <Features/>
        {/*<First/>*/}
        {/*<FeaturesWithScreenshot/>*/}
        <Roadmap/>
        {/*<Third/>*/}
    </Box>
)
