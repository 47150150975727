import { extendTheme } from "@chakra-ui/react";
import '@fontsource/titillium-web/900.css';

const layerStyles = {
    logoName: {
        fontFamily: 'Titillium Web',
    },
};

const textStyles = {
    logoName: {
        fontFamily: 'Titillium Web',
        fontSize: ["3xl", "5xl"],
    },
};

const config = {
    useSystemColorMode: false,
    cssVarPrefix: "niftyhub",
    initialColorMode: 'dark',
};

export default extendTheme({ config, layerStyles, textStyles });